import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Headings/Title"

const TermsofServices = ({ data }) => {
  const { frontmatter, body } = data.allMdx.edges[0].node

  return (
    <Layout>
      <Seo title="Terms of Services" />
      <section className="blog-post-section">
        <Title>{frontmatter.title}...</Title>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-12 col-sm-12 mb-4">
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-body">
                  <div className="card-text">
                    <MDXRenderer>{body}</MDXRenderer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsofServices

export const query = graphql`
  query TermsofServices {
    allMdx(
      filter: { fileAbsolutePath: { regex: "//terms-of-services.mdx/" } }
    ) {
      edges {
        node {
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`
